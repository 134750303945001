import { lazy } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @type {{
    path: string;
    component:React.LazyExoticComponent<({ state, to, title }: {
        state: any;
        to?: string;
        title?: string;
    }) => React.JSX.Element>;
    temp: string;
    featureFlag?: string | undefined;
    subpage?: {
    path: string;
    component:React.LazyExoticComponent<({ state, to, title }: {
        state: any;
        to?: string;
        title?: string;
    }) => React.JSX.Element>;
    temp: string;
    featureFlag?: string | undefined;
    }[] | undefined;
}[]}
 */
const pages = [
  {
    path: "*",

    component: lazy(() => import("./views/404/404.jsx")),
    temp: "404",
  },
  {
    path: "/",

    component: lazy(() => import("./views/home/Home.jsx")),
    temp: "Home",
  },
  // { path: "/about-us", temp: "About" },
  {
    path: "/games/:slug",
    component: lazy(() => import("./views/game/Game.jsx")),
    temp: "Game",
    subpage: [
      {
        path: "compete-2-win?",
        component: lazy(
          () => import("./views/game/description/components/Compete2Win.jsx")
        ),
        temp: "Compete 2 Win",
        subpage: [
          {
            path: "tournaments?",
            component: lazy(
              () =>
                import("./views/game/description/components/Tournaments.jsx")
            ),
            temp: "Tournaments",
            subpage: [
              {
                path: "ongoing?",
                component: lazy(
                  () =>
                    import(
                      "./views/game/description/components/Tournaments/OngoingTournaments.jsx"
                    )
                ),
                temp: "Ongoing",
              },
              {
                path: "upcoming",
                component: lazy(
                  () =>
                    import(
                      "./views/game/description/components/Tournaments/UpcomingTournaments.jsx"
                    )
                ),
                temp: "Upcoming",
              },
              {
                path: "ended",
                component: lazy(
                  () =>
                    import(
                      "./views/game/description/components/Tournaments/EndedTournaments.jsx"
                    )
                ),
                temp: "Ended",
              },
              {
                path: "history",
                component: lazy(
                  () =>
                    import(
                      "./views/game/description/components/Tournaments/HistoryTournaments.jsx"
                    )
                ),
                temp: "History",
              },
            ],
          },
          {
            path: "rooms",
            component: lazy(
              () => import("./views/game/description/components/Rooms.jsx")
            ),
            temp: "Rooms",
            subpage: [
              {
                path: "ongoing?",
                component: lazy(() => import("./views/rooms/OngoingRooms.jsx")),
                temp: "Ongoing",
              },
              {
                path: "history",
                component: lazy(() => import("./views/rooms/HistoryRooms.jsx")),
                temp: "History",
              },
            ],
          },
        ],
      },
      {
        path: "media",
        component: lazy(
          () => import("./views/game/description/components/Media.jsx")
        ),
        temp: "Media",
      },
      {
        path: "about",
        component: lazy(
          () => import("./views/game/description/components/About.jsx")
        ),
        temp: "About This Game",
      },
      {
        path: "items",
        component: lazy(
          () => import("./views/game/description/components/Items.jsx")
        ),
        temp: "Game Items",
      },
    ],
  },
  {
    path: "/games/:slug/play",
    component: lazy(() => import("./views/game/play/Play.jsx")),
    temp: "Play",
  },
  {
    path: "/contact",
    component: lazy(() => import("./views/contact/Contact.jsx")),
    temp: "Contact",
  },
  {
    path: "/marketplace",

    component: lazy(() => import("./views/market/Market.jsx")),
    temp: "Market",
  },
  {
    path: "/shop",

    component: lazy(() => import("./views/shop/")),
    temp: "Shop",
  },
  {
    path: "/marketplace/:slug",

    component: lazy(() => import("./views/asset/Asset.jsx")),
    temp: "Asset",
  },
  {
    path: "/reset-password",

    component: lazy(() => import("./views/reset/Reset.jsx")),
    temp: "Reset",
  },
  {
    path: "/backpack",
    component: lazy(() => import("./views/backpack/Backpack.jsx")),
    temp: "Backpack",
  },
  {
    path: "/compete-2-win",
    component: lazy(() => import("./views/tournaments/Tournaments.jsx")),
    temp: "Compete-2-Win",
    subpage: [
      {
        path: "tournaments?",
        component: lazy(
          () => import("./views/tournaments/components/TournamentList.jsx")
        ),
        temp: "TournamentList",
        subpage: [
          {
            path: "ongoing?",
            component: lazy(
              () =>
                import(
                  "./views/tournaments/components/Tournaments/OngoingTournaments.jsx"
                )
            ),
            temp: "Ongoing",
          },
          {
            path: "upcoming",
            component: lazy(
              () =>
                import(
                  "./views/tournaments/components/Tournaments/UpcomingTournaments.jsx"
                )
            ),
            temp: "Upcoming",
          },
          {
            path: "ended",
            component: lazy(
              () =>
                import(
                  "./views/tournaments/components/Tournaments/EndedTournaments.jsx"
                )
            ),
            temp: "Ended",
          },
          {
            path: "history",
            component: lazy(
              () =>
                import(
                  "./views/tournaments/components/Tournaments/HistoryTournaments.jsx"
                )
            ),
            temp: "History",
          },
        ],
      },
      {
        path: "rooms",
        component: lazy(
          () => import("./views/tournaments/components/RoomList.jsx")
        ),
        temp: "Rooms",
        featureFlag: "game-rooms",
        subpage: [
          {
            path: "",
            component: lazy(() => import("./views/rooms/OngoingRooms.jsx")),
            temp: "Ongoing",
          },
          {
            path: "history",
            component: lazy(() => import("./views/rooms/HistoryRooms.jsx")),
            temp: "History",
          },
        ],
      },
    ],
  },
  {
    path: "/compete-2-win?/rooms/history/:slug",
    component: lazy(() => import("./views/room/HistoryRoom.jsx")),
    temp: "HistoryRoom",
    featureFlag: "game-rooms",
  },
  {
    path: "/compete-2-win?/rooms/:slug",
    component: lazy(() => import("./views/room/Room.jsx")),
    temp: "Room",
    featureFlag: "game-rooms",
  },
  // BACKWARD COMPATIBILITY ROUTES
  {
    path: "/tournaments/rooms/history/:slug",
    component: lazy(() => import("./views/room/HistoryRoom.jsx")),
    temp: "HistoryRoom",
    featureFlag: "game-rooms",
  },
  {
    path: "/tournaments/rooms/:slug",
    component: lazy(() => import("./views/room/Room.jsx")),
    temp: "Room",
    featureFlag: "game-rooms",
  },
  // END BACKWARD COMPATIBILITY ROUTES
  {
    path: "/community-leaderboard",
    temp: "Community",
    component: lazy(() => import("./views/community/Community.jsx")),
    featureFlag: "communities-leaderboard",
  },
  {
    path: "/loot-packs",
    component: lazy(() => import("./views/lootpacks/LootPacks.jsx")),
    temp: "LootPacks",
  },
  {
    path: "/compete-2-win?/tournaments/:slug",
    component: lazy(() => import("./views/tournament/Tournament.jsx")),
    temp: "Tournament",
  },
  {
    path: "/promotions",

    component: lazy(() => import("./views/promotions/Promotions.jsx")),
    temp: "Promotions",
    featureFlag: "promotions",
  },
  {
    path: "/loyalty",

    component: lazy(() => import("./views/loyalty/Loyalty.jsx")),
    temp: "Loyalty",
  },
  {
    path: "/faq",
    component: lazy(() => import("./views/faq/FAQ.jsx")),
    temp: "FAQ",
  },
  {
    path: "/terms-and-conditions",
    component: lazy(() => import("./views/terms/Terms.jsx")),
    temp: "Terms",
  },
  {
    path: "/privacy-policy",

    component: lazy(() => import("./views/policy/Policy.jsx")),
    temp: "Policy",
  },
  {
    path: "/scratch-cards",

    component: lazy(() => import("./views/scratch/Scratch.jsx")),
    temp: "Scratch",
  },
  {
    path: "/lucky-funatic",

    component: lazy(() => import("./views/lucky/Lucky.jsx")),
    temp: "Lucky Funatic",
  },
  {
    path: "/tico",

    component: lazy(() => import("./views/tico/Tico.jsx")),
    temp: "Tico",
  },
  {
    path: "/wheel-of-fortune",
    component: lazy(() => import("./views/wheel/Wheel.jsx")),
    temp: "Wheel",
    featureFlag: "wheel-of-fortune",
  },
  {
    path: "/promotional-campaigns",
    component: lazy(
      () => import("./views/promotional-campaigns/PromotionalCampaigns.jsx")
    ),
    temp: "Promotional Campaigns",
    featureFlag: "promotions",
  },
  {
    path: "/profile",
    temp: "Profile",
    component: lazy(() => import("./views/profile/Profile.jsx")),
    subpage: [
      {
        path: "settings",

        component: lazy(() => import("./views/profile/profile/Settings.jsx")),
        temp: "Settings",
      },
      {
        path: "verification",
        component: lazy(
          () => import("./views/profile/profile/Verification.jsx")
        ),
        temp: "Verification",
        featureFlag: "identity-verification",
      },
      {
        path: "rank-and-progress",

        component: lazy(() => import("./views/profile/profile/Progress.jsx")),
        temp: "Progress",
        featureFlag: "ranks-and-seasons",
      },
      {
        path: "history",
        component: lazy(() => import("./views/profile/profile/History.jsx")),
        temp: "History",
      },
      {
        path: "connected-accounts",
        component: lazy(
          () => import("./views/profile/profile/ConnectedAccounts.jsx")
        ),
        temp: "Connected Accounts",
        featureFlag: "social-connect",
      },
      {
        path: "appearance",
        component: lazy(() => import("./views/profile/profile/Appearance.jsx")),
        temp: "Appearance",
      },
      {
        path: "campaigns",
        component: lazy(
          () =>
            import("./views/profile/profile/PromotionalCampaignsRedirect.jsx")
        ),
        temp: "Campaigns",
      },
    ],
  },
];

function getRoutes(pagesList) {
  return pagesList.reduce(
    (processedPages, { path, component, subpage, ...rest }) => {
      let children;
      if (subpage !== undefined) {
        children = getRoutes(subpage);
      }
      const processedPage = {
        path,
        component,
        ...rest,
        ...(children ? { children } : {}),
      };

      processedPages.push(processedPage);
      return processedPages;
    },
    []
  );
}

export const routes = getRoutes(pages);
