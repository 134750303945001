let store = {
  _state: {
    basePath: "/",
    opened_popup: { open: false },
    isMobile: window.innerWidth < 1025,
    isPageLoading: true,
    showWallet: false,
    documentTitle: "Funtico",
    pageTitle: null,
    country: "",
    appearance: null,
    simplexWidget: false,
    countries: false,
    socialConnections: false,
    showNavigation: true,
    roomItems: [],
    roomHistoryItems: [],
    scrollTop: true,
  },

  _callSubscriber() {},

  getState() {
    return this._state;
  },

  subscribe(observer) {
    this._callSubscriber = observer;
  },

  setPopUp(type, arr) {
    this._state.opened_popup = {
      // ...this._state.opened_popup,
      ...arr,
      open: type,
    };
    this._callSubscriber();
  },
  setDeviceType(width) {
    this._state.isMobile = width;
    this._callSubscriber();
  },
  setShowWallet(boo) {
    this._state.showWallet = boo;
    this._callSubscriber();
  },
  setAppearance(data) {
    this._state.appearance = data;
    this._callSubscriber();
  },
  setCountry(cntr) {
    this._state.country = cntr;
    this._callSubscriber();
  },
  setLoading(active) {
    this._state.isPageLoading = active;
    this._callSubscriber();
  },
  setPageTitle(title) {
    this._state.pageTitle = title;
    this._callSubscriber();
  },
  setSimplexWidget(boo) {
    this._state.simplexWidget = boo;
    if (boo) {
      this.setPopUp("simplexWidget");
    } else {
      this.setPopUp("");
    }
    // this._callSubscriber(this.getState());
  },
  setCountries(c) {
    this._state.countries = c;
    this._callSubscriber();
  },
  setSocialConnections(c) {
    this._state.socialConnections = c;
    this._callSubscriber();
  },
  setShowNavigation(show) {
    this._state.showNavigation = show;
    this._callSubscriber();
  },
  setRoomItems(c) {
    this._state.roomItems = c;
    this._callSubscriber();
  },
  setRoomHistoryItems(c) {
    this._state.roomHistoryItems = c;
    this._callSubscriber();
  },
  setScrollTop(scroll) {
    this._state.scrollTop = scroll;
    this._callSubscriber();
  },
};

export default store;
